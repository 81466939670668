import React from "react";

// Custom styles
import "./product.styles.scss";

// Bootstrap components
import Col from "react-bootstrap/Col";

export function ProductCard({ image, productName }) {
  return (
    <Col xs={12} lg={4} xl={3}>
      <div className="product__card">
        <div className="product__card__media">
          <img width={'77px'} height={'77px'} src={image} alt="FMM" />
        </div>
        <p>{productName}</p>
      </div>
    </Col>
  );
}
