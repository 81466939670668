import React from "react";

// Custom styles
import "./review.styles.scss";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RatingViewHeart from "../Raiting/RaitingViewHeart";

// Media assets
import women from "../../images/women.svg";
import men from "../../images/men.svg";
import transgender from "../../images/transgender.svg";
import moment from "moment";
import localization from "moment/locale/es";

import {useTranslation} from "gatsby-plugin-react-i18next";

export default function ReviewCard({
  genreImg,
  reviewerName,
  reviewerAge,
  reviewDate,
  review,
  rating = 5,
  locationRating = 0,
  attentionRating = 0,
  responseRating = 0,
  supportRating = 0,
}) {
  // TODO check this icons
  let genderIcons = [
    { gender: "female", icon: women },
    { gender: "male", icon: men },
    { gender: "non-binary", icon: men },
    { gender: "prefer-not-to-answer", icon: women },
    { gender: "prefer-to-self-describe", icon: transgender },
  ];
  const { t, i18n } = useTranslation();

  let icon = 'transgender';
  if (genreImg !== null) {
    icon = genderIcons.find((icon) => icon.gender === genreImg.toLowerCase());
  }

  if (icon == null) {
    icon = { icon: women };
  }
  let locale = window.navigator.userLanguage || window.navigator.language;
  console.log(locale);
  moment.locale(locale, localization);

  function formatDate(reviewDate) {
    let date = moment.unix(reviewDate);

    return date.format("DD MMMM YYYY");
  }

  return (
    <Col xs={12}>
      <div className="review__card">
        <Row>
          <div className="review__card__heading">
            <Col
              xs={12}
              lg={7}
              className="review__card__heading__reviewer-info align-items-center"
            >
              <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <img src={icon.icon} alt="FMM" />
                <p>
                  <span>{reviewerName},</span> {reviewerAge} {t("years")}
                </p>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <RatingViewHeart val={rating} />
            </Col>
            <Col xs={12} lg={2} className="review__card__heading__date">
              <p>{formatDate(reviewDate)}</p>
            </Col>
          </div>
        </Row>
        <Row className="mt-lg-4">
          <div className="review__card__content">
            <Col xs={12} lg={5}>
              <div className="review__card__content__rating">
                <p>{t("Location")}:</p>
                <div>
                  <RatingViewHeart val={locationRating} />
                </div>
              </div>
              <div className="review__card__content__rating">
                <p>{t("Attention")}:</p>
                <div>
                  <RatingViewHeart val={attentionRating} />
                </div>
              </div>
              <div className="review__card__content__rating">
                <p>{t("Response Time")}:</p>
                <div>
                  <RatingViewHeart val={responseRating} />
                </div>
              </div>
              <div className="review__card__content__rating">
                <p>{t("Support")}:</p>
                <div>
                  <RatingViewHeart val={supportRating} />
                </div>
              </div>
            </Col>
            <Col xs={12} lg={7}>
              <p
                style={{
                  bottom: 0,
                  left: 0,
                  width: "100&",
                  verticalAlign: "middle",
                  wordWrap: "break-word",
                }}
              >
                {review}
              </p>
            </Col>
          </div>
        </Row>
      </div>
    </Col>
  );
}
