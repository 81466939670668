import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

// Bootstrap styles
import "bootstrap/dist/css/bootstrap.min.css";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom components
import CustomNav from "../../components/Nav/CustomNav";
import {CustomButton} from "../../components/Buttons/Buttons";
import Footer from "../../components/Footer/Footer";
import {ProductCard} from "../../components/Product/ProductCard";
import ReviewCard from "../../components/Review/ReviewCard";
import MainMap from "../../components/Maps/MainMap";

// Custom styles
import "../../assets/scss/reviews.styles.scss";

// Media assets
import pinIconUrl from "../../images/icon-pin.svg";
import phoneIconUrl from "../../images/icon-phone.svg";
import clockIconUrl from "../../images/clock.svg";
import fbIconUrl from "../../images/icon-social-2.svg";
import waIconUrl from "../../images/icon-social-1.svg";
import shareIconUrl from "../../images/share--white.svg";
import hormonalIudInsertion from "../../images/hormonal-iud.svg";
import iudInsertion from "../../images/non-hormonal-iud.svg";
import implant from "../../images/implant.svg";
import pillIconUrl from "../../images/pill.svg";
import injectableIconUrl from "../../images/injectable.svg";
import patchIconUrl from "../../images/patch.svg";
import ringIconUrl from "../../images/ring.svg";
import condomFemaleIconUrl from "../../images/internal-condom-female.svg";
import condomMaleIconUrl from "../../images/external-condom-male.svg";
import emergencyPillIconUrl from "../../images/emergency-pill.svg";
import sterilizationIconUrl from "../../images/sterilization.svg";
import cervicalCapIconUrl from "../../images/cervical-cap.svg";
import diapgragmIconUrl from "../../images/diaphragm.svg";
import spermicideIconUrl from "../../images/spermicide.svg";
import spongeIconUrl from "../../images/sponge.svg";
import vasectomyUrl from "../../images/vasectomy_2.svg";
import familyPlanning from "../../images/Icons_family_planing_counseling_2.svg";
import hivDetection from "../../images/hiv_attention_2.svg";
import stiDetection from "../../images/sti_detection_2.svg";
import FacilityIcon from "../../components/Facility/FacilityTypeIcon";
import ShareModal from "../../components/ShareModal";

import {graphql} from "gatsby";
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next";
import RatingViewHeart from "../../components/Raiting/RaitingViewHeart";

// dummy data
const providerProducts = [
  {
    id: 1,
    productName: "External Condoms",
    productImage: condomMaleIconUrl,
  },
  {
    id: 2,
    productName: "Internal Condoms",
    productImage: condomFemaleIconUrl,
  },
  {
    id: 3,
    productName: "Daily contraceptive pill",
    productImage: pillIconUrl,
  },
  {
    id: 4,
    productName: "Contraceptive injection",
    productImage: injectableIconUrl,
  },
  {
    id: 5,
    productName: "Patch",
    productImage: patchIconUrl,
  },
  {
    id: 6,
    productName: "Ring",
    productImage: ringIconUrl,
  },
  {
    id: 7,
    productName: "Emergency contraceptive pill",
    productImage: emergencyPillIconUrl,
  },
  {
    id: 8,
    productName: "Cervical cap",
    productImage: cervicalCapIconUrl,
  },
  {
    id: 9,
    productName: "Diaphragm",
    productImage: diapgragmIconUrl,
  },
  {
    id: 10,
    productName: "Spermicide",
    productImage: spermicideIconUrl,
  },
  {
    id: 11,
    productName: "Sponge",
    productImage: spongeIconUrl,
  },
];

// dummy data
const providerServices = [
  {
    id: 1,
    productName: "IUD insertion",
    productImage: iudInsertion,
  },
  {
    id: 2,
    productName: "Hormonal IUD insertion",
    productImage: hormonalIudInsertion,
  },
  {
    id: 3,
    productName: "Implant insertion",
    productImage: implant,
  },
  {
    id: 4,
    productName: "Contraceptive Injection application",
    productImage: injectableIconUrl,
  },
  {
    id: 5,
    productName: "Female sterilization",
    productImage: sterilizationIconUrl,
  },
  {
    id: 6,
    productName: "Vasectomy",
    productImage: vasectomyUrl,
  },
  {
    id: 7,
    productName: "Family planning",
    productImage: familyPlanning,
  },
  {
    id: 8,
    productName: "Sexual education",
    productImage: familyPlanning,
  },
  {
    id: 9,
    productName: "STI detection",
    productImage: stiDetection,
  },
  {
    id: 10,
    productName: "HIV detection",
    productImage: hivDetection,
  },
];

// Team Card

function TeamCard({ teamMemberImg, teamMember, teamMemberPosition }) {
  return (
    <Col xs={6} lg={3} xl={2} className="text-center">
      <div className="team-card">
        <img src={teamMemberImg} alt="fmm" />
        <p>{teamMember}</p>
        <p>{teamMemberPosition}</p>
      </div>
    </Col>
  );
}
let lngOffset = 0.014;

export default function ProviderReview(props) {
  const { t } = useTranslation();
  const { language } = useI18next();

  const [contentLoaded, setContentLoaded] = useState(false);
  const [myData, setMyData] = useState({});
  const [shareModal, toggleShareModal] = useState(null);

  const id = props.params.id;

  useEffect(() => {
    fetch(`${process.env.GATSBY_REST_API_URL}/reviews/provider/${id}`)
      .then((response) => response.json())
      .then((resultData) => {
        setMyData(resultData);
      });
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      lngOffset = 0;
    }
  }, []);
  useEffect(() => {
    myData.data && setContentLoaded(true);
  }, [myData]);
  let reviews = myData.data;
  const [contentLoadedProvider, setContentLoadedProvider] = useState(false);
  const [myDataProvider, setMyDataProvider] = useState({});

  useEffect(() => {
    fetch(`${process.env.GATSBY_REST_API_URL}/provider/${id}`)
      .then((responseProvider) => responseProvider.json())
      .then((resultDataProvider) => {
        setMyDataProvider(resultDataProvider);
      });
  }, []);
  let provider = [];
  let providersMarkers = [];
  let mainMap = null;

  useEffect(() => {
    myDataProvider && setContentLoadedProvider(true);
  }, [myDataProvider]);

  if (!isEmpty(myDataProvider)) {
    provider = myDataProvider;
    providersMarkers = [
      {
        id: provider.id,
        latitude: parseFloat(provider.latitude),
        longitude: parseFloat(provider.longitude),
        title: provider.name,
      },
    ];

    mainMap = (
      <MainMap
        default_lat={providersMarkers[0]?.latitude}
        default_lng={providersMarkers[0]?.longitude + lngOffset}
        zoom={15}
        markers={providersMarkers}
        height={"765px"}
        defaultStyle={false}
        geometryColor="#aff5cd"
        lines="#4aec91"
      />
    );
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  let products = provider.products;
  let intersectionProducts = [];
  if (products && products.length > 0) {
    products?.forEach((product) => {
      console.log(product);
      providerProducts.find(function (providerProduct) {
        if (providerProduct.id === parseInt(product.id)) {
          intersectionProducts.push(providerProduct);
        }
      });
    });
    intersectionProducts = [...new Set(intersectionProducts)];
  }

  let services = provider.services;
  let intersectionServices = [];
  if (services && services.length > 0) {
    services?.forEach((service) => {
      providerServices.find(function (providerService) {
        if (providerService.id === parseInt(service.id)) {
          intersectionServices.push(providerService);
        }
      });
    });
    intersectionServices = [...new Set(intersectionServices)];
  }

  function stringToArray(string) {
    return string.split(",");
  }

  const showShareModal = (e) => {
    e.preventDefault();
    toggleShareModal(window.location.href);
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: language }}>
        <meta charSet="utf-8" />
        {/* <!-- HTML Meta Tags --> */}
        <title>
          {t("Find My Method: Profiles of Birth Control Providers")}
        </title>
        <meta
          name="description"
          content={t(
            "Find profiles for Birth Control and Contraceptives, check ratings and reviews to proceed further and make selections."
          )}
        />
        <meta
          name="keywords"
          content="Contraceptive Services, Contraceptive Method"
        />
        <link rel="canonical" href={process.env.GATSBY_SITE_URL}/>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://findmymethod.org/wp-content/uploads/2019/05//apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://findmymethod.org/wp-content/uploads/2019/05//favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://findmymethod.org/wp-content/uploads/2019/05//favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="https://findmymethod.org/wp-content/uploads/2019/05//site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="https://findmymethod.org/wp-content/uploads/2019/05//safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <CustomNav theme="dark" />

      <main>
        <title>Provider Review</title>
        <section className="provider-hero">
          <Row className="h-100 g-0">
            {mainMap}
            <Col xs={12} lg={6} />
            <Col xs={12} lg={6}>
              <div className="provider-hero__container">
                <div className="provider-hero__reviews__rating">
                  <RatingViewHeart val={provider?.average ?? 5} />
                </div>
                <h3>{provider?.name}</h3>
                <div className="provider-hero__type">
                  <div>
                    {/* <img src={pharmacyIconUrl} alt="Pharmacy" /> */}
                    {FacilityIcon(provider?.type_facility)}
                    <p>{provider?.facility_human_name}</p>
                  </div>
                  <div>
                    <img src={clockIconUrl} alt="Contraceptive Services" />
                    <p>{provider?.hours ?? t("Not Available")}</p>
                  </div>
                </div>
                <div className="provider-hero__info">
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${provider?.address}`}
                    target="_blank"
                  >
                    <img src={pinIconUrl} alt="Contraceptive Services" />
                    {provider?.address}
                  </a>
                  <a href={`tel:${provider?.phone_contact}`}>
                    <img src={phoneIconUrl} alt="Contraceptive Services" />
                    {provider?.phone_contact}
                  </a>
                </div>
                <div className="provider-hero__social">
                  <a href={provider?.facebook_link} target={"_blank"}>
                    <img src={fbIconUrl} alt="Contraceptive Services" />
                  </a>
                  <a
                    href={`https://wa.me/${provider?.whatsapp_contact}`}
                    target={"_blank"}
                  >
                    <img src={waIconUrl} alt="Contraceptive Services" />
                  </a>
                </div>
                <div className="provider-hero__cta">
                  <a
                    href={`/review?id=${provider?.id}`}
                    className="btn--blue"
                  >
                    {t("Write a review")}
                  </a>
                  <a href="" className="btn--blue" onClick={showShareModal}>
                    <img src={shareIconUrl} alt="FMM" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <ShareModal isShow={shareModal} onClose={() => toggleShareModal(null)} url={shareModal} />
        <section className="listing" style={{ marginTop: "5%" }}>
          <Container>
            <h2>{t("Listing Products")}</h2>
            <p>
              {t(provider?.facility_human_name)} {provider?.name}{" "}
              {t("has the following services for you.")}
            </p>
            <div className="listing__container">
              <Row>
                {intersectionProducts?.map((product) => (
                  <ProductCard
                    key={product?.id}
                    image={product?.productImage}
                    productName={t(product?.productName)}
                  />
                ))}
              </Row>
            </div>
          </Container>
          <Container></Container>
          <Container>
            <h2>{t("Listing Services")}</h2>
            <p>
              {provider?.facility_human_name} {provider?.name}{" "}
              {t("has the following services for you.")}
            </p>
            <div className="listing__container">
              <Row>
                {intersectionServices?.map((service) => (
                  <ProductCard
                    key={service?.id}
                    image={service?.productImage}
                    productName={t(service?.productName)}
                  />
                ))}
              </Row>
            </div>
          </Container>
        </section>
        <section className="reviews">
          <Container>
            <h2>{t("Reviews")}</h2>
            <p>
              {t(
                "Find My Method provides content intended for informational purposes only and is not affiliated with a medical organization."
              )}
            </p>
            <div className="reviews__container">
              <Row>
                {reviews?.map((review) => (
                  <ReviewCard
                    key={review.id}
                    genreImg={review.gender}
                    reviewerName={review.name_alias}
                    reviewerAge={review.age}
                    reviewDate={review.created_at}
                    review={review.experience}
                    rating={review.review}
                    locationRating={review.location}
                    attentionRating={review.attention}
                    responseRating={review.response_time}
                    supportRating={review.support}
                  />
                ))}
              </Row>
            </div>
          </Container>
        </section>
        <section className="support">
          <Container>
            <h2>{t("Need Help?")}</h2>
            <p>
              {t("Our team is just one")}{" "}
              <a href={"mailTo:info@findmymethod.org"}>{t("email")}</a>{" "}
              {t("away")}.
            </p>
            {/*<div className="support__container">*/}
            {/*    <Row>*/}
            {/*        {team.map((member) => (*/}
            {/*            <TeamCard*/}
            {/*                key={member.id}*/}
            {/*                teamMemberImg={member.teamMemberImg}*/}
            {/*                teamMember={member.teamMember}*/}
            {/*                teamMemberPosition={member.teamMemberPosition}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*    </Row>*/}
            {/*</div>*/}
            {/*<Button color="orange" text="Contact Us" size="auto"/>*/}
          </Container>
        </section>
      </main>

      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
